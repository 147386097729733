import { Component, Inject, OnInit } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from '@app/core/services/toast.service';
import {
    deliveryNoteNumberValidator,
    orderNumberValidator,
    originalConsignorValidator,
} from '@app/shared/form-validators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { DeliveryNoteInPool } from 'api/models';
import { DeliveryNotesService } from 'api/services';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-edit-note-dialog',
    templateUrl: './edit-note-dialog.component.html',
    styleUrls: ['./edit-note-dialog.component.scss'],
    standalone: false,
})
export class EditNoteDialogComponent implements OnInit {
    loading = false;
    patchDeliveryNoteForm: UntypedFormGroup;
    minDate: Date = new Date(
        new Date().setFullYear(new Date().getFullYear() - 10)
    );
    touchedDate = false;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: { note: DeliveryNoteInPool; organizationSiteKey: string },
        private deliveryNoteService: DeliveryNotesService,
        private toastr: ToastService,
        private dialogRef: MatDialogRef<EditNoteDialogComponent>,
        private translate: TranslateService,
        private formBuilder: UntypedFormBuilder
    ) {}

    ngOnInit(): void {
        this.patchDeliveryNoteForm = this.formBuilder.group({
            fileName: [this.data.note.fileName],
            number: [
                this.data.note.number,
                [
                    Validators.required,
                    deliveryNoteNumberValidator(),
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
            dateAnnounced: [
                this.data.note.dateAnnounced
                    ? new Date(this.data.note.dateAnnounced)
                    : null,
            ],
            orderNumber: [
                this.data.note.orderNumber || '',
                [
                    orderNumberValidator(),
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
            originalConsignor: [
                this.data.note.originalConsignor || '',
                [originalConsignorValidator()],
            ],
            referenceNumber: [
                this.data.note.properties?.referenceNumber || '',
                [originalConsignorValidator()],
            ],
        });
    }

    async saveChanges(): Promise<void> {
        if (this.patchDeliveryNoteForm.invalid) {
            this.toastr.warning(this.translate.instant('SHARED.FORM-INVALID'));
            this.patchDeliveryNoteForm.markAllAsTouched();
            return;
        }

        this.loading = true;
        const formValue = this.patchDeliveryNoteForm.value;

        const dateAnnouncedTimestamp = formValue.dateAnnounced
            ? formValue.dateAnnounced.valueOf()
            : 0;

        const requestBody = {
            fileName: formValue.fileName,
            number: formValue.number,
            orderNumber: formValue.orderNumber,
            originalConsignor: formValue.originalConsignor,
            dateAnnounced: dateAnnouncedTimestamp,
            properties: {
                referenceNumber: formValue.referenceNumber || '',
            },
        };

        try {
            await lastValueFrom(
                this.deliveryNoteService.patchDeliveryNoteMetadata({
                    organizationSiteKey: this.data.organizationSiteKey,
                    deliveryNoteKey: this.data.note._key,
                    body: requestBody,
                })
            );
            this.toastr.success(
                this.translate.instant(
                    'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.NOTE-UPDATED'
                )
            );

            const updatedNote = this.data.note;
            updatedNote.fileName = formValue.fileName;
            updatedNote.number = formValue.number;
            updatedNote.dateAnnounced = formValue.dateAnnounced;
            updatedNote.orderNumber = formValue.orderNumber;
            updatedNote.originalConsignor = formValue.originalConsignor;

            if (!updatedNote.properties) {
                updatedNote.properties = {};
            }
            updatedNote.properties.referenceNumber =
                formValue.referenceNumber || '';

            this.dialogRef.close(updatedNote);
        } catch (error: any) {
            console.error('Error updating delivery note:', error);
            this.toastr.error(this.translate.instant('SHARED.ERRORS.GENERAL'));
        } finally {
            this.loading = false;
        }
    }

    get formControls(): { [p: string]: AbstractControl } {
        return this.patchDeliveryNoteForm.controls;
    }
}
