<div class="tw-flex tw-h-[400px] tw-flex-col">
    <div class="tw-flex-grow">
        <div class="tw-flex tw-justify-between">
            <h1
                mat-dialog-title
                class="tw-break-all">
                {{ 'EXTERNAL-SIGNATURE-PAD.DELIVERY-NO' | translate }}
                {{ data.note.number }}
            </h1>

            <button
                mat-icon-button
                mat-dialog-close>
                <mat-icon fontSet="material-symbols-outlined">close</mat-icon>
            </button>
        </div>

        <form
            [formGroup]="patchDeliveryNoteForm"
            class="tw-w-full tw-overflow-hidden">
            <mat-form-field appearance="fill">
                <mat-label>{{
                    'OUTGOING-GOODS.UPLOAD.DELIVERY-NOTE-NUMBER' | translate
                }}</mat-label>
                <input
                    formControlName="number"
                    [placeholder]="
                        'OUTGOING-GOODS.UPLOAD.DELIVERY-NOTE-NUMBER' | translate
                    "
                    matInput />
                <mat-error *ngIf="formControls.number.invalid">{{
                    'OUTGOING-GOODS.UPLOAD.ERROR.NOT-MATCHING-CRITERIAS'
                        | translate
                }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>{{
                    'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.ORDER-NO'
                        | translate
                }}</mat-label>
                <input
                    formControlName="orderNumber"
                    [placeholder]="
                        'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.ORDER-NO'
                            | translate
                    "
                    matInput />
                <mat-error
                    >{{
                        'OUTGOING-GOODS.UPLOAD.ERROR.ORDERNUMBER-NOT-MATCHING'
                            | translate
                    }}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>{{
                    'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.ADVISED-DATE'
                        | translate
                }}</mat-label>
                <input
                    formControlName="dateAnnounced"
                    [placeholder]="
                        'OUTGOING-GOODS.FOLDER-VIEW.TABLE-HEADER.ADVISED-DATE'
                            | translate
                    "
                    matInput
                    (click)="picker.open()"
                    (dateChange)="touchedDate = true"
                    [matDatepicker]="picker"
                    [min]="minDate" />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error
                    *ngIf="
                        formControls.dateAnnounced.errors &&
                        formControls.dateAnnounced.errors.matDatepickerParse
                    "
                    >{{
                        'EDIT-NOTE-DIALOG.INVALID-DATE' | translate
                    }}</mat-error
                >
                <mat-error
                    *ngIf="
                        formControls.dateAnnounced.errors &&
                        formControls.dateAnnounced.errors.matDatepickerMin
                    ">
                    {{ 'EDIT-NOTE-DIALOG.DATE-PAST' | translate }}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>
                    {{ 'GLOBAL.ORIGINAL_CONSIGNOR' | translate }}</mat-label
                >
                <input
                    aria-label="ORIGINAL_CONSIGNOR-form"
                    matInput
                    formControlName="originalConsignor" />
                <mat-error>{{
                    'EDIT-NOTE-DIALOG.ORIGINAL_CONSIGNOR_ERROR' | translate
                }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>
                    {{ 'GLOBAL.REFERENCE_NUMBER' | translate }}</mat-label
                >
                <input
                    aria-label="REFERENCE_NUMBER-form"
                    matInput
                    formControlName="referenceNumber" />
                <mat-error>{{
                    'EDIT-NOTE-DIALOG.ORIGINAL_CONSIGNOR_ERROR' | translate
                }}</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div
        mat-dialog-actions
        align="end">
        <button
            mat-button
            mat-dialog-close>
            {{ 'OUTGOING-GOODS.EDIT-NOTE-DIALOG.CANCEL' | translate }}
        </button>
        <button
            mat-raised-button
            color="primary"
            (click)="saveChanges()"
            cdkFocusInitial
            [class.spinner]="loading"
            [disabled]="!patchDeliveryNoteForm.valid">
            {{ 'OUTGOING-GOODS.EDIT-NOTE-DIALOG.ACTION' | translate }}
        </button>
    </div>
</div>
